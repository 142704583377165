var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mianBox" },
    [
      _c("div", { staticClass: "mianLeft" }, [
        _c(
          "div",
          { staticClass: "tree-search" },
          [
            _c("v-input", {
              attrs: {
                "prefix-icon": "el-icon-search",
                placeholder: "请输入名称",
                width: 220,
              },
              model: {
                value: _vm.filterText,
                callback: function ($$v) {
                  _vm.filterText = $$v
                },
                expression: "filterText",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "tree-wrap" }, [
          _c(
            "div",
            { staticClass: "tree-group_1" },
            [
              _c("v-tree", {
                key: 2,
                attrs: {
                  data: _vm.companyTreeData,
                  defaultProps: _vm.companyTreeProps,
                  lazy: true,
                  "expand-on-click-node": false,
                  filterText: _vm.filterText,
                  filterNodeMethod: _vm.filterNodeMethod,
                  highlightCurrent: true,
                },
                on: { loadData: _vm.treeLoad, nodeClick: _vm.selectNode },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "mainRight" },
        [
          _vm.communityId
            ? _c(
                "div",
                { staticClass: "mainRight_btnBox" },
                [
                  _c(
                    "v-button",
                    {
                      staticClass: "mainRight_btnBox_btn",
                      attrs: { permission: "synchronous" },
                      on: { click: _vm.showSynchronousDialog },
                    },
                    [_vm._v("同步至其他项目")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: {
                data: _vm.treeData,
                "row-key": "id",
                border: "",
                height: "700",
                "default-expand-all": "",
                "tree-props": _vm.defaultProps_treeData,
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "分类", width: "180" },
              }),
              _c("el-table-column", {
                attrs: { label: "接单时间", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.receiveTime) +
                              _vm._s(scope.row.receiveUnit)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "处理准备时间", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.processTime) +
                              _vm._s(scope.row.processUnit)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "完成处理时间", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.finishTime) +
                              _vm._s(scope.row.finishUnit)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "serviceTime", label: "服务时间", width: "180" },
              }),
              _c("el-table-column", {
                attrs: { prop: "isCharge", label: "是否收费", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(_vm._s(scope.row.isCharge == 0 ? "否" : "是")),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return scope.row.childrenList.length == 0
                          ? [
                              _c(
                                "v-button",
                                {
                                  attrs: {
                                    type: "text",
                                    size: "mini",
                                    permission: "serviceSetting",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showAddDialog(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("\n          服务设置\n        ")]
                              ),
                            ]
                          : undefined
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑分类", visible: _vm.dialogNewlyVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogNewlyVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "newClassify_header" }, [
            _c("div", { staticClass: "tips" }, [_vm._v("基本信息")]),
            _c("div", { staticClass: "newClassify_item" }, [
              _c("div", { staticClass: "categoryName" }, [
                _c("div", { staticClass: "tipsBox" }, [
                  _c("div", { staticClass: "tipsBox-icon" }, [_vm._v("*")]),
                  _c("div", { staticClass: "tipsBox-text" }, [
                    _vm._v("分类名称"),
                  ]),
                ]),
                _c("div", { staticClass: "categoryName_name" }, [
                  _vm._v(_vm._s(_vm.editFormData.name)),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "categoryName" },
                [
                  _c("div", { staticClass: "tipsBox" }, [
                    _c("div", { staticClass: "tipsBox-icon" }, [_vm._v("*")]),
                    _c("div", { staticClass: "tipsBox-text" }, [
                      _vm._v("预约服务时间"),
                    ]),
                  ]),
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "categoryName_name",
                      model: {
                        value: _vm.editFormData.radio1,
                        callback: function ($$v) {
                          _vm.$set(_vm.editFormData, "radio1", $$v)
                        },
                        expression: "editFormData.radio1",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("开启"),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("关闭"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.editFormData.radio1 === "1"
                ? _c(
                    "div",
                    { staticClass: "categoryName" },
                    [
                      _c("div", { staticClass: "tipsBox" }, [
                        _c("div", { staticClass: "tipsBox-icon" }, [
                          _vm._v("*"),
                        ]),
                        _c("div", { staticClass: "tipsBox-text" }, [
                          _vm._v("服务时间规则"),
                        ]),
                      ]),
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "categoryName_name",
                          model: {
                            value: _vm.editFormData.radio5,
                            callback: function ($$v) {
                              _vm.$set(_vm.editFormData, "radio5", $$v)
                            },
                            expression: "editFormData.radio5",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("固定时间"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editFormData.radio1 === "1"
                ? _c("div", { staticClass: "categoryName" }, [
                    _c("div", { staticClass: "tipsBox" }, [
                      _c("div", { staticClass: "tipsBox-icon" }, [_vm._v("*")]),
                      _c("div", { staticClass: "tipsBox-text" }, [
                        _vm._v("服务时间"),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "categoryName_name" },
                      [
                        _c("el-time-picker", {
                          staticClass: "TimeSelect",
                          attrs: {
                            placeholder: "选择开始时间",
                            format: "HH:mm",
                            "value-format": "HH:mm",
                            size: "small",
                          },
                          model: {
                            value: _vm.editFormData.startTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.editFormData, "startTime", $$v)
                            },
                            expression: "editFormData.startTime",
                          },
                        }),
                        _c("div", { staticClass: "interval" }, [
                          _vm._v(" ——— "),
                        ]),
                        _c("el-time-picker", {
                          staticClass: "TimeSelect",
                          attrs: {
                            placeholder: "选择结束时间",
                            format: "HH:mm",
                            "value-format": "HH:mm",
                            size: "small",
                          },
                          model: {
                            value: _vm.editFormData.endTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.editFormData, "endTime", $$v)
                            },
                            expression: "editFormData.endTime",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "newClassify_bottom" }, [
            _c("div", { staticClass: "tips" }, [_vm._v("处理时效")]),
            _c("div", { staticClass: "newClassify-item" }, [
              _c("div", { staticClass: "proTime-item" }, [
                _c("div", { staticClass: "tipsBox" }, [
                  _c("div", { staticClass: "tipsBox-icon" }, [_vm._v("*")]),
                  _c("div", { staticClass: "tipsBox-text" }, [
                    _vm._v("要求接单时间"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "timeSelect" },
                  [
                    _c("div", { staticClass: "timeSelect-tips" }, [
                      _vm._v("下单后"),
                    ]),
                    _c("v-input", {
                      staticClass: "inputBox_input",
                      attrs: { placeholder: "请输入时间" },
                      on: { blur: _vm.checkReceiveTime },
                      model: {
                        value: _vm.editFormData.receiveTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.editFormData, "receiveTime", $$v)
                        },
                        expression: "editFormData.receiveTime",
                      },
                    }),
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.editFormData.receiveUnit,
                          callback: function ($$v) {
                            _vm.$set(_vm.editFormData, "receiveUnit", $$v)
                          },
                          expression: "editFormData.receiveUnit",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "分钟" } }),
                        _c("el-radio-button", { attrs: { label: "小时" } }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "proTime-item" }, [
                _c("div", { staticClass: "tipsBox" }, [
                  _c("div", { staticClass: "tipsBox-icon" }, [_vm._v("*")]),
                  _c("div", { staticClass: "tipsBox-text" }, [
                    _vm._v("要求开始处理时间"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "timeSelect" },
                  [
                    _c("div", { staticClass: "timeSelect-tips" }, [
                      _vm._v("接单后"),
                    ]),
                    _c("v-input", {
                      staticClass: "inputBox_input",
                      attrs: { placeholder: "请输入时间" },
                      on: { blur: _vm.checkProcessTime },
                      model: {
                        value: _vm.editFormData.processTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.editFormData, "processTime", $$v)
                        },
                        expression: "editFormData.processTime",
                      },
                    }),
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.editFormData.processUnit,
                          callback: function ($$v) {
                            _vm.$set(_vm.editFormData, "processUnit", $$v)
                          },
                          expression: "editFormData.processUnit",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "分钟" } }),
                        _c("el-radio-button", { attrs: { label: "小时" } }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "proTime-item" }, [
                _c("div", { staticClass: "tipsBox" }, [
                  _c("div", { staticClass: "tipsBox-icon" }, [_vm._v("*")]),
                  _c("div", { staticClass: "tipsBox-text" }, [
                    _vm._v("要求完成时间"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "timeSelect" },
                  [
                    _c("div", { staticClass: "timeSelect-tips" }, [
                      _vm._v("处理后"),
                    ]),
                    _c("v-input", {
                      staticClass: "inputBox_input",
                      attrs: { placeholder: "请输入时间" },
                      on: { blur: _vm.checkFinishTime },
                      model: {
                        value: _vm.editFormData.finishTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.editFormData, "finishTime", $$v)
                        },
                        expression: "editFormData.finishTime",
                      },
                    }),
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.editFormData.finishUnit,
                          callback: function ($$v) {
                            _vm.$set(_vm.editFormData, "finishUnit", $$v)
                          },
                          expression: "editFormData.finishUnit",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "分钟" } }),
                        _c("el-radio-button", { attrs: { label: "小时" } }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "newClassify-tips" }, [
                _vm._v("如若开启服务时间，则根据服务时间进行超时判断"),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "charge" }, [
            _c("div", { staticClass: "charge_header" }, [
              _c("div", { staticClass: "tips" }, [_vm._v("涉及收费")]),
              _c("div", { staticClass: "charge_item" }, [
                _c(
                  "div",
                  { staticClass: "chargeName" },
                  [
                    _c("div", { staticClass: "tipsBox" }, [
                      _c("div", { staticClass: "tipsBox-icon" }, [_vm._v("*")]),
                      _c("div", { staticClass: "tipsBox-text" }, [
                        _vm._v("是否收费"),
                      ]),
                    ]),
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "chargeName_name",
                        model: {
                          value: _vm.chargeRadio,
                          callback: function ($$v) {
                            _vm.chargeRadio = $$v
                          },
                          expression: "chargeRadio",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "1" } }, [
                          _vm._v("否"),
                        ]),
                        _c("el-radio", { attrs: { label: "2" } }, [
                          _vm._v("是"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.chargeRadio === "2"
                  ? _c(
                      "div",
                      { staticClass: "chargeName" },
                      [
                        _c("div", { staticClass: "tipsBox" }, [
                          _c("div", { staticClass: "tipsBox-icon" }, [
                            _vm._v("*"),
                          ]),
                          _c("div", { staticClass: "tipsBox-text" }, [
                            _vm._v("收费科目"),
                          ]),
                        ]),
                        _c(
                          "el-form",
                          { staticClass: "chargeName_name" },
                          [
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "v-select2",
                                  _vm._b(
                                    {
                                      staticClass: "chargeSubjectSelect",
                                      attrs: { placeholder: "查询收费科目" },
                                      model: {
                                        value: _vm.editFormData.projectId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editFormData,
                                            "projectId",
                                            $$v
                                          )
                                        },
                                        expression: "editFormData.projectId",
                                      },
                                    },
                                    "v-select2",
                                    _vm.chargeSubjectParams,
                                    false
                                  )
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "chargeName" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.chargeRadio === "2",
                          expression: "chargeRadio === '2'",
                        },
                      ],
                      staticClass: "chargeText",
                    },
                    [
                      _c("div", { staticClass: "tipsBox" }, [
                        _c("div", { staticClass: "tipsBox-text" }, [
                          _vm._v("收费说明"),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "chargeContent" },
                        [
                          _c("v-input", {
                            staticClass: "materialFee",
                            attrs: {
                              width: Number(300),
                              placeholder: "请输入材料收费说明",
                              maxlength: Number(20),
                              label: "材料费",
                            },
                            model: {
                              value: _vm.editFormData.materialFee,
                              callback: function ($$v) {
                                _vm.$set(_vm.editFormData, "materialFee", $$v)
                              },
                              expression: "editFormData.materialFee",
                            },
                          }),
                          _c("v-input", {
                            staticClass: "laborFee",
                            attrs: {
                              width: Number(300),
                              placeholder: "请输入人工费收费说明",
                              maxlength: Number(20),
                              label: "人工费",
                            },
                            model: {
                              value: _vm.editFormData.laborFee,
                              callback: function ($$v) {
                                _vm.$set(_vm.editFormData, "laborFee", $$v)
                              },
                              expression: "editFormData.laborFee",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "chargeTextarea" },
                            [
                              _c("span", [_vm._v("说明")]),
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 2,
                                  width: Number(300),
                                  placeholder: "请输入其他收费标准说明",
                                  maxlength: Number(100),
                                  "show-word-limit": "",
                                },
                                on: { input: _vm.change },
                                model: {
                                  value: _vm.editFormData.description,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editFormData,
                                      "description",
                                      $$v
                                    )
                                  },
                                  expression: "editFormData.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitFormData },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogNewlyVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "项目信息", visible: _vm.dialogSynchronousVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSynchronousVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "synchronousTitle" }, [
            _vm._v(
              "您可在复制您当前配置的工单分类信息至其他项目，会覆盖其他项目当前已有的工单配置信息。"
            ),
          ]),
          _c(
            "div",
            { staticClass: "synchronousTree" },
            [
              _vm.dialogSynchronousVisible
                ? _c("v-tree", {
                    attrs: {
                      "node-key": _vm.nodeKey,
                      defaultProps: _vm.companyTreeProps,
                      lazy: true,
                      "expand-on-click-node": false,
                      showCheckbox: true,
                    },
                    on: {
                      loadData: _vm.SynchronousTreeLoad,
                      checkChange: _vm.handleCheckChange,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.synSubmit } },
                [_vm._v("同步")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogSynchronousVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="mianBox">
    <!-- 工单分类页面哦 -->
    <div class="mianLeft">
      <!-- 搜索框 -->
      <div class="tree-search">
        <v-input
          v-model="filterText"
          prefix-icon="el-icon-search"
          placeholder="请输入名称"
          :width="220"
        />
      </div>

      <!-- 控件树 -->
      <div class="tree-wrap">
        <div class="tree-group_1">
          <v-tree
            :data="companyTreeData"
            :defaultProps="companyTreeProps"
            :lazy="true"
            :expand-on-click-node="false"
            :filterText="filterText"
            :filterNodeMethod="filterNodeMethod"
            :highlightCurrent="true"
            @loadData="treeLoad"
            @nodeClick="selectNode"
            :key="2"
          ></v-tree>
        </div>
      </div>
    </div>

    <div class="mainRight">
      <div class="mainRight_btnBox" v-if="communityId">
        <v-button class="mainRight_btnBox_btn" permission="synchronous" @click="showSynchronousDialog">同步至其他项目</v-button>
      </div>

      <!-- <div class="mainRight_listTips">
        <div class="mainRight_listTips_classificat">分类</div>
        <div class="mainRight_listTips_orderTime">接单时间</div>
        <div class="mainRight_listTips_prepTime">处理准备时间</div>
        <div class="mainRight_listTips_complTime">完成处理时间</div>
        <div class="mainRight_listTips_serTime">服务时间</div>
        <div class="mainRight_listTips_operation">操作</div>
      </div> -->

      <!-- <div class="mainRight_tree">
        <el-tree
          class="el_tree"
          :data="treeData"
          :props='defaultProps_treeData'
          :indent="30"
          node-key="id"
          default-expand-all
          draggable
        >
          <span class="custom-tree-node" slot-scope="{ node, data }"  @mouseleave= mouseleave(data,$event)>
            <span @mouseover=mouseover(data,$event) class="tree-node-title">{{ node.label }}</span>
            <span class="custom-tree-btn" @mouseover=mouseover(data,$event)>
              <span class="tree-btn-orderTime">{{data.receiveTime}}{{data.receiveUnit}}</span>
              <span class="tree-btn-prepTime">{{data.processTime}}{{data.processUnit}}</span>
              <span class="tree-btn-complTime">{{data.finishTime}}{{data.finishUnit}}</span>
              <span class="tree-btn-serTime">{{data.serviceTime}}</span>
              <el-button
                type="text"
                size="mini"
                :class="['tree-btn-operation', 'none_' + data.id]"
                permission="serviceSetting"
                @click="showAddDialog(data)">
                服务设置
              </el-button>
            </span>
          </span>
        </el-tree>
      </div> -->

      <el-table
        :data="treeData"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        border
        height="700"
        default-expand-all
        :tree-props="defaultProps_treeData">
        <el-table-column
          prop="name"
          label="分类"
          width="180">
        </el-table-column>
        <el-table-column
          label="接单时间"
          width="160">
          <template slot-scope="scope">
            <span>{{ scope.row.receiveTime}}{{ scope.row.receiveUnit}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="处理准备时间"
          width="160">
          <template slot-scope="scope">
            <span>{{ scope.row.processTime}}{{ scope.row.processUnit}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="完成处理时间"
          width="160">
          <template slot-scope="scope">
            <span>{{ scope.row.finishTime}}{{ scope.row.finishUnit}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="serviceTime"
          label="服务时间"
          width="180">
        </el-table-column>
        <!-- 新增是否收费 -->
        <el-table-column
          prop="isCharge"
          label="是否收费"
          width="160">
          <template slot-scope="scope">{{scope.row.isCharge == 0 ? '否' : '是'}}</template>
        </el-table-column>
        <el-table-column
          label="操作">
          <template  v-if="scope.row.childrenList.length == 0" slot-scope="scope">
            <v-button
            type="text"
            size="mini"
            permission="serviceSetting"
            @click="showAddDialog(scope.row)">
            服务设置
          </v-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title="编辑分类" :visible.sync="dialogNewlyVisible">
      <div class="newClassify_header">
        <div class="tips">基本信息</div>
        <div class="newClassify_item">
          <div class="categoryName">
            <div class="tipsBox">
              <div class="tipsBox-icon">*</div>
              <div class="tipsBox-text" >分类名称</div>
            </div>

            <div class="categoryName_name">{{editFormData.name}}</div>
          </div>

          <div class="categoryName">
            <div class="tipsBox">
              <div class="tipsBox-icon">*</div>
              <div class="tipsBox-text" >预约服务时间</div>
            </div>

            <el-radio-group class="categoryName_name"  v-model="editFormData.radio1">
              <el-radio label="1">开启</el-radio>
              <el-radio label="2">关闭</el-radio>
            </el-radio-group>
          </div>

          <div class="categoryName" v-if="editFormData.radio1==='1'">
            <div class="tipsBox">
              <div class="tipsBox-icon">*</div>
              <div class="tipsBox-text" >服务时间规则</div>
            </div>

            <el-radio-group class="categoryName_name" v-model="editFormData.radio5">
              <el-radio label="1">固定时间</el-radio>
            </el-radio-group>
          </div>

          <div class="categoryName" v-if="editFormData.radio1==='1'">
            <div class="tipsBox">
              <div class="tipsBox-icon">*</div>
              <div class="tipsBox-text" >服务时间</div>
            </div>

            <div class="categoryName_name">
              <el-time-picker
                class="TimeSelect"
                placeholder="选择开始时间"
                v-model="editFormData.startTime"

                format = 'HH:mm'
                value-format = 'HH:mm'
                size="small"
              ></el-time-picker>
              <div class="interval"> ——— </div>
              <el-time-picker
                class="TimeSelect"
                placeholder="选择结束时间"
                v-model="editFormData.endTime"
                format="HH:mm"
                value-format = 'HH:mm'
                size="small"
              ></el-time-picker>
            </div>
          </div>
        </div>
      </div>

      <div class="newClassify_bottom">
        <div class="tips">处理时效</div>
        <div class="newClassify-item">
          <div class="proTime-item">
            <div class="tipsBox">
              <div class="tipsBox-icon">*</div>
              <div class="tipsBox-text" >要求接单时间</div>
            </div>
            <div class="timeSelect">
              <div class="timeSelect-tips">下单后</div>
              <v-input @blur="checkReceiveTime" v-model="editFormData.receiveTime" placeholder="请输入时间" class="inputBox_input" />
              <el-radio-group v-model="editFormData.receiveUnit">
                <el-radio-button label="分钟"></el-radio-button>
                <el-radio-button label="小时"></el-radio-button>
              </el-radio-group>
            </div>
          </div>

          <div class="proTime-item">
            <div class="tipsBox">
              <div class="tipsBox-icon">*</div>
              <div class="tipsBox-text" >要求开始处理时间</div>
            </div>
            <div class="timeSelect">
              <div class="timeSelect-tips">接单后</div>
              <v-input @blur="checkProcessTime" v-model="editFormData.processTime" placeholder="请输入时间" class="inputBox_input" />
              <el-radio-group v-model="editFormData.processUnit">
                <el-radio-button label="分钟"></el-radio-button>
                <el-radio-button label="小时"></el-radio-button>
              </el-radio-group>
            </div>
          </div>

          <div class="proTime-item">
            <div class="tipsBox">
              <div class="tipsBox-icon">*</div>
              <div class="tipsBox-text" >要求完成时间</div>
            </div>
            <div class="timeSelect">
              <div class="timeSelect-tips">处理后</div>
              <v-input @blur="checkFinishTime" v-model="editFormData.finishTime" placeholder="请输入时间" class="inputBox_input" />
              <el-radio-group v-model="editFormData.finishUnit">
                <el-radio-button label="分钟"></el-radio-button>
                <el-radio-button label="小时"></el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <div class="newClassify-tips">如若开启服务时间，则根据服务时间进行超时判断</div>
        </div>
      </div>
      <!-- 涉及收费 -->
      <div class="charge">
        <div class="charge_header">
          <div class="tips">涉及收费</div>
          <!-- 是否收费 -->
          <div class="charge_item">
            <div class="chargeName">
              <div class="tipsBox">
                <div class="tipsBox-icon">*</div>
                <div class="tipsBox-text" >是否收费</div>
              </div>
              <el-radio-group class="chargeName_name"  v-model="chargeRadio">
                <el-radio label="1">否</el-radio>
                <el-radio label="2">是</el-radio>
              </el-radio-group>
            </div>
          <!-- 收费科目 -->
            <div v-if="chargeRadio === '2'" class="chargeName">
              <div class="tipsBox">
                <div class="tipsBox-icon">*</div>
                <div class="tipsBox-text" >收费科目</div>
              </div>
              <el-form class="chargeName_name">
                <el-form-item>
                  <v-select2 class="chargeSubjectSelect"  placeholder="查询收费科目" v-model="editFormData.projectId" v-bind="chargeSubjectParams"></v-select2>
                </el-form-item>
              </el-form>

            </div>

          <!-- 收费说明 -->
            <div class="chargeName">
              <div v-show="chargeRadio === '2'" class="chargeText">
                <div class="tipsBox">
                  <div class="tipsBox-text">收费说明</div>
                </div>
                <div class="chargeContent">
                  <v-input class="materialFee" :width="Number(300)" v-model="editFormData.materialFee" placeholder="请输入材料收费说明" :maxlength="Number(20)" label="材料费"></v-input>
                  <v-input class="laborFee" :width="Number(300)" v-model="editFormData.laborFee" placeholder="请输入人工费收费说明" :maxlength="Number(20)" label="人工费"></v-input>
                  <div class="chargeTextarea">
                    <span>说明</span>
                    <el-input type="textarea" :rows=2 :width="Number(300)"  placeholder="请输入其他收费标准说明" :maxlength="Number(100)" show-word-limit v-model="editFormData.description" @input="change"></el-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFormData">确 定</el-button>
        <el-button @click="dialogNewlyVisible=false">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="项目信息" :visible.sync="dialogSynchronousVisible">
      <div class="synchronousTitle">您可在复制您当前配置的工单分类信息至其他项目，会覆盖其他项目当前已有的工单配置信息。</div>
      <div class="synchronousTree">
        <v-tree
          v-if="dialogSynchronousVisible"
          :node-key="nodeKey"
          :defaultProps="companyTreeProps"
          :lazy="true"
          :expand-on-click-node="false"
          :showCheckbox="true"
          @loadData="SynchronousTreeLoad"
          @checkChange="handleCheckChange"
        ></v-tree>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="synSubmit">同步</el-button>
        <el-button @click="dialogSynchronousVisible=false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getRegionTreeURL, getTreeURL, updateURL, copyURL ,chargeSubjectURl,getFeeItemURL} from "./api";
import { vTree, vInput } from "components/control/index";
import { Tree } from "element-ui";
import VButton from '../../../components/control/v-button/v-button.vue';
import { vUeditor } from 'components/control'

export default {
  name: "OrderSchedulingClassificationList",
  components: {
    vTree,
    "el-tree": Tree,
    "v-input": vInput,
    VButton,
    vUeditor
  },
  data() {
    let _this = this;
    return {
      chargeData:{},
      width:200,
      chargeRadio:1,
      // projectId:'',
      nodeKey: 'id',
      companyTreeData: [],
      companyTreeProps: {
        children: 'children',
        label: 'name',
        isLeaf: (data, node) => {
          return !data.isParent
        }
      },
      filterText: "",
      treeData: [],
      treeProps: {
        children: "children",
        label: "orgName",
        isLeaf: (data, node) => {
          return true;
        },
      },
      defaultProps: {
        children: "children",
        label: "label"
      },
      defaultProps_treeData: {
        label: "name",
        children: 'childrenList'
      },
      synchronous_data: [],
      synchronous_defaultProps: {
        children: 'children',
        label: 'name',
        isLeaf: (data, node) => {
          return !data.isParent
        }
      },
      dialogNewlyVisible: false,  // 服务设置按钮点击出现弹窗
      dialogSynchronousVisible: false,  // 同步项目服务弹窗
      editData: {
        name: '',
        radio1: '',
        radio5: '',
        startTime: '',      // 服务开始时间
        endTime: '',        // 服务结束时间
        receiveUnit: null,
        processUnit: null,
        finishUnit: null,
        receiveTime: 0,
        processTime: 0,
        finishTime: 0
      },  // 被编辑的分类的数据
      editFormData: {
        name: '',
        radio1: '',
        radio5: '',
        startTime: '',      // 服务开始时间
        endTime: '',        // 服务结束时间
        receiveUnit: null,
        processUnit: null,
        finishUnit: null,
        receiveTime: 0,
        processTime: 0,
        finishTime: 0,
        description:'',
        materialFee:'',
        laborFee:'',
        projectId:''
      },
      orgId: '',
      communityId: '', // 小区id
      targetIds: [], // 需要同步的小区id
      _targetIds: [],
      chargeSubjectParams: {
        searchUrl: chargeSubjectURl,
        request: {
          text: 'subjectName',
          value: 'id'
        },
        response: {
          text: 'subjectName',
          value: 'id'
        }
      }
    };
  },
  async created() {},
  computed: {},
  mounted() {},
  methods: {
    // 获取左侧公司列表 方法
    getProjectTree (level, id) {
      let params = {
        nodeLevel: level
      }
      id && (params.id = id)

      return new Promise((resolve, reject) => {
        this.$axios.get(getRegionTreeURL, { params }).then(res => {
          if (res.status === 100) {
            let { data } = res
            resolve(data)
          }
        })
      })
    },
    async treeLoad (node, resolve) {
      let { nodeLevel, id } = node.data
      let list = await this.getProjectTree(nodeLevel, id)
      this.synchronous_data = list;
      setTimeout(() => {
        return resolve(list)
      }, 500)
    },

    // 获取同步小区tree方法
    getSynchronousTree (id) {
      let params = {
        id: id
      }

      return new Promise((resolve, reject) => {
        this.$axios.get(getRegionTreeURL, { params }).then(res => {
          if (res.status === 100) {
            let { data } = res
            resolve(data)
          }
        })
      })
    },
    async SynchronousTreeLoad (node, resolve) {
      let id = this.orgId;
      let list = await this.getSynchronousTree(id)
      return resolve(list)
    },

    // 选中公司的某个节点之后 获取对应的工单数据
    selectNode (data) {
      let { orgId, id, nodeLevel} = data;
      if(nodeLevel >= 1) {
        this.orgId = orgId;
        let params = {
          communityId: nodeLevel === 0 ? orgId : id
        }
        this.communityId = id;
        return new Promise((resolve, reject) => {
          this.$axios.get(getTreeURL, { params }).then(res => {
            if (res.status === 100) {
              let { data } = res
              this.treeData = data
            }
          })
        })
      }
    },
    toShowData(id) {
      let params = {
        communityId: id
      }
      return new Promise((resolve, reject) => {
        this.$axios.get(getTreeURL, { params }).then(res => {
          if (res.status === 100) {
            let { data } = res
            this.treeData = data
          }
        })
      })
    },
    filterNodeMethod(value, data) {
      // 搜索时 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) return true;
      return data[this.companyTreeProps.label].indexOf(value) !== -1;
    },
    mouseleave(data,$event){
      let dom = document.querySelector(`.none_${data.id}`);
      dom.style.opacity = 0;
    },
    mouseover(data,$event){
      let dom = document.querySelector(`.none_${data.id}`);
      dom.style.opacity = 1;

    },
    async showAddDialog(data) {
      let header = {
        headers: {
           defCommunityId:this.communityId
        }
      }
      await this.$axios.get(`${getFeeItemURL}?orgId=${this.communityId}&typeId=${data.id}`,header).then(res=>{
        if(res.status === 100 && res.data){
          let {materialFee,laborFee,description,projectId } = res.data
          // this.projectId = projectId

          // 解决editFormData被后面影响的问题
          this.chargeData =  {
            materialFee,laborFee,description,projectId
          }
        }else {
          // 清空之前的数据
          this.editFormData.materialFee = ''
          this.editFormData.laborFee = ''
          this.editFormData.description = ''
          this.editFormData.projectId = ''
          this.chargeData = {}
        }
      })
      let editFormData = Object.assign(this.editData, data,this.chargeData)
      if(editFormData.serviceTime) {
        editFormData.radio1 = '1';
        editFormData.radio5 = '1';
        editFormData.startTime = editFormData.serviceTime.split('-')[0];
        editFormData.endTime = editFormData.serviceTime.split('-')[1];
      } else {
        editFormData.radio1 = '2';
        editFormData.radio5 = '1';
        this.editData.startTime = '';
        editFormData.startTime = '';
        this.editData.endTime = '';
        editFormData.endTime = '';
      }
      this.editFormData = editFormData;
      this.dialogNewlyVisible = true;
      this.chargeRadio = data.isCharge + 1 + ''
    },
    // 编辑弹窗方法 start
    // 校验接单时间输入框 方法
    checkReceiveTime() {
      if(!this.editFormData.receiveTime) {
        // this.$message.error('请输入接单时间');
      }
      if(this.editFormData.receiveUnit === '分钟' && this.editFormData.receiveTime % 60 === 0) {
        this.editFormData.receiveTime = this.editFormData.receiveTime / 60;
        this.editFormData.receiveUnit = '小时';
      }
    },
    // 校验处理时间输入框 方法
    checkProcessTime() {
      if(!this.editFormData.processTime) {
        // this.$message.error('请输入处理时间');
      }
      if(this.editFormData.processUnit === '分钟' && this.editFormData.processTime % 60 === 0) {
        this.editFormData.processTime = this.editFormData.processTime / 60;
        this.editFormData.processUnit = '小时';
      }

    },
    // 校验完成时间输入框 方法
    checkFinishTime() {
      if(!this.editFormData.finishTime) {
        // this.$message.error('请输入完成时间');
      }
      if(this.editFormData.finishUnit === '分钟' && this.editFormData.finishTime % 60 === 0) {
        this.editFormData.finishTime = this.editFormData.finishTime / 60;
        this.editFormData.finishUnit = '小时';
      }
    },
    // 提交方法
    submitFormData() {
      let editFormData = this.editFormData;
      const { id, name, parentId, receiveTime, processTime, finishTime, startTime, endTime }  = editFormData;
      // 校验规则代码
      let timeInterval = 0;

      let reg = /^[0-9]+$/;
      let processTimeNum = Number(processTime);
      if(!receiveTime || !processTime || !finishTime) {
        this.$message.error('您的处理时效部分，有时间没有设置完全！');
        return;
      } else if(!reg.test(receiveTime) || !reg.test(processTime) || !reg.test(finishTime)) {
        this.$message.error('请检查您输入的时间是否是正确！');
        return;
      }
      if(editFormData.radio1 === '1' && (!startTime || !endTime)) {
        this.$message.error('您有服务时间没有设置！');
        return;
      } else if (editFormData.radio1 === '1' ) {
        let [ num1 , num2 ]  = startTime.split(':');
        let [ num3 , num4 ]  = endTime.split(':');
        num1 = Number(num1);
        num2 = Number(num2);
        num3 = Number(num3);
        num4 = Number(num4);

        timeInterval = ( num3 - num1) * 60 + (num4 - num2);
        if(startTime == '00:00' && endTime === '00:00') {
          timeInterval = 24 * 60;
          // 设置为24小时, 00:00 - 00:00 可以被设置，其他时间则不能重复
        } else if( num1 > num3 || (num1 == num3 && num2 > num4) ) {
          this.$message.error('您的开始服务时间大于结束服务时间，请重新设置！');
          return;
        }

        if(timeInterval === 0) {
          this.$message.error('您的开始服务时间等于结束服务时间，请重新设置！');
          return;
        } else {
          let norm;
          editFormData.processUnit === '小时'? norm = 60:norm = 1;
          processTimeNum = processTimeNum * norm
        }

        if(timeInterval < processTimeNum ) {
          this.$message.error('您的处理时间小于设置服务时间范围，请重新设置！');
          return;
        }
      }

      // 输入框输入规则校验
      // 要求接单时间 验证
      if(editFormData.receiveUnit === '小时') {
        if(receiveTime < 1 ||  receiveTime > 24) {
          this.$message.error('您的要求接单时间请在1~24整数小时中选择输入！');
          return
        }
      } else {
        if(receiveTime < 1 || receiveTime > 500) {
          this.$message.error('您的要求接单时间请在1~500整数分钟中选择输入！');
          return
        }
      }

      // 要求开始处理时间 验证
      if(editFormData.processUnit === '小时') {
        if(processTime < 1 ||  processTime > 24) {
          this.$message.error('您的要求开始处理时间请在1~24整数小时中选择输入！');
          return
        }
      } else {
        if(processTime < 1 || processTime > 500) {
          this.$message.error('您的要求开始处理时间请在1~500整数分钟中选择输入！');
          return
        }
      }

      // 计算时间 准备传值提交
      let receiveTimeNum1,
          processTimeNum1,
          finishTimeNum1;
      receiveTimeNum1 = editFormData.receiveUnit === '小时'?editFormData.receiveTime * 60 : editFormData.receiveTime;
      processTimeNum1 = editFormData.processUnit === '小时'?editFormData.processTime * 60 : editFormData.processTime;
      finishTimeNum1 = editFormData.finishUnit === '小时'?editFormData.finishTime * 60 : editFormData.finishTime;

      let workOrderTypeF = {
        id: id,
        orgId: this.communityId,
        type: name,
        parentId: parentId,
        receiveTime: receiveTimeNum1,
        processTime: processTimeNum1,
        finishTime: finishTimeNum1,
        serviceTime: editFormData.radio1 === '1'?`${startTime}-${endTime}`: '',
        projectId:this.editFormData.projectId,
        description:this.editFormData.description,
        materialFee:this.editFormData.materialFee,
        laborFee:this.editFormData.laborFee
      }
      // 收费科目规则校验
      if(this.chargeRadio === '2'){
        if(!this.editFormData.projectId){
          this.$message({
            message: '请选择收费科目',
            type: 'warning'
          })
          return
        }
      }
      if(this.chargeRadio === '1'){
        delete workOrderTypeF.projectId
      }


      // 提交接口调用
      this.$axios.post(updateURL, workOrderTypeF).then(res => {
        if (res.status === 100 && res.data) {
          this.$message({
            message: '修改分类成功',
            type: 'success'
          });

          this.toShowData(this.communityId);
          // this.selectNode()
          this.dialogNewlyVisible = false;
        }
      })

    },
    // 编辑弹窗方法 end

    // 同步弹窗方法
    // 点击同步按钮
    showSynchronousDialog() {
      this.dialogSynchronousVisible = true;
    },
    // 选择需要同步的项目的方法
    handleCheckChange(data, checked, indeterminate) {
      var arr = [].concat(this.targetIds);
      if(!checked) {
        arr.forEach((value, index) => {
          if(value === data.id) {
            arr.splice(index, 1)
          }
        })
      } else {
        arr.push(data.id)
      }

      this.targetIds = arr;
    },
    synSubmit() {
      let parame = {
        communityId: this.communityId,
        targetIds: this.targetIds,
      }
      this.$axios.post(copyURL, parame).then(res => {
        if (res.status === 100 && res.data) {
          this.$message({
            message: '同步分类成功',
            type: 'success'
          });
          this.targetIds = [];
          this.dialogSynchronousVisible = false;
          this.toShowData(this.communityId);
        }
      })

    },
    change(){
      this.$forceUpdate()
    }
  }
};
</script>
<style lang="scss" scoped>
.mianBox {
  width: 100%;
  box-sizing: border-box;
  display: flex;

  .mianLeft {
    padding: 0 20px;
    box-sizing: border-box;

    .tree-wrap {
      margin-top: 20px;
      height: calc(100% - 93px);
      width: 240px;

      .tree-group_1 {
        width: 100%;
        height: 100%;
        padding-right: 20px;
        overflow: auto;
        box-sizing: border-box;
        text-align: left;
      }
    }
  }

  .mainRight {

    .mainRight_btnBox {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
    }

    .mainRight_btnBox_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 114px;
      height: 40px;
      background-color: rgba(22, 155, 213, 1);
      border-radius: 5px;
      font-weight: 400;
      color: #FFFFFF;
      font-size: 13px;
    }


    .mainRight_tree {
      max-height: 700px;
      box-sizing: border-box;
      overflow: auto;
    }

    // .mainRight_tree::after {
    //   content: '';
    //   display: block;
    //   width: 100%;
    //   height: 1px;
    //   margin-top: 40px;
    // }
  }

  .newClassify_header {
    display: flex;
    flex-direction: column;

    .tips {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      font-size: 18px;
      color: #333;
    }

    .newClassify_item {
      display: flex;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      padding: 30px;

      .categoryName {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .tipsBox {
          flex: 20%;
          display: flex;
          align-content: center;
          font-size: 14px;

          .tipsBox-icon {
            color: #FF0101;
          }
        }

        .categoryName_name {
          display: flex;
          flex: 70%;
          font-size: 16px;
          color: #333;
          text-align: left;

          .TimeSelect {
            width: 140px;
          }

          .interval {
            margin: 0 10px;
            color: #b4bccc;
          }
        }
      }
    }
  }

  .newClassify_bottom {
    display: flex;
    flex-direction: column;

    .tips {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      font-size: 18px;
      color: #333;
    }

    .newClassify-item {
      display: flex;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      padding: 30px;

      .proTime-item {
        display: flex;
        width: 100%;
        font-size: 14px;
        align-items: center;
        margin-bottom: 20px;

        .tipsBox {
          flex: 28%;
          display: flex;
          align-items: center;

          .tipsBox-icon {
            color: #FF0101;
          }
        }

        .timeSelect {
          flex: 72%;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .inputBox_input {
            margin: 0 15px;
          }
        }
      }

      .newClassify-tips {
        text-align: left;
      }
    }
  }

  // 涉及收费
  .charge_header {
    display: flex;
    flex-direction: column;

    .tips {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      font-size: 18px;
      color: #333;
    }
    // 是否收费
    .charge_item {
      display: flex;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      padding: 30px;

      .chargeName {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .tipsBox {
          flex: 20%;
          display: flex;
          align-content: center;
          font-size: 14px;

          .tipsBox-icon {
            color: #FF0101;
          }
        }

        .chargeName_name {
          display: flex;
          flex: 70%;
          font-size: 16px;
          color: #333;
          text-align: left;
        }
        // 收费说明
        .chargeText{
          flex: 20%;
          display: flex;
          margin-bottom: 20px;
          margin-left: 6px;
          .tipsBox {

            display: flex;
            align-content: center;
            font-size: 14px;

            .tipsBox-icon {
              color: #FF0101;
            }
          }
          .chargeContent{
            flex: 70%;
            display: flex;
            flex-direction: column;
            text-align: left;
            .materialFee{
              display: block;
              margin-bottom:10px
            }
            .laborFee{
              display: block;
              margin-bottom:10px
            }
            .chargeTextarea{
              display: flex;
              span{
                width: 47px;
              }
              ::v-deep .el-textarea{
                width: 70%;
              }
            }
          }

        }
        // 收费科目
        .chargeSubjectSelect{
          padding-top: 20px;
        }
      }
    }

  }



  .synchronousTree {
    max-height: 60vh;
    overflow: auto;
    text-align: left;
  }
}

</style>
